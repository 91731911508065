wfl.main = (function () {
    var publicMain = {};
    var privateMain = {};
    
    publicMain.init = function () {
        $(".back-top-top").on("click", privateMain.backToTop);

        // On self
        $('#modal-selfuser--update').on('submit', 'form', privateMain.userSelfUpdate);
        
        publicMain.toolTips();
    
        $(document).on("click", ".responsive-status", privateMain.mobileTableStatus);
        privateMain.handleMobileRowStatus();

        $('li.dropdown :first-child').on('click', function() {
            const $el = $(this).parent();
            const $a = $el.children('a.dropdown-toggle');
            if ($a.length && $a.attr('href')) {
                location.href = $a.attr('href');
            }
        });
    };
    
    /**
     *
     * @param errorHTML
     * @param $errorContainer
     */
    publicMain.showErrors = function (errorHTML, $errorContainer) {
        $errorContainer.html(errorHTML);
    };
    
    /**
     *
     * @param $errorContainer
     */
    publicMain.resetErrors = function ($errorContainer) {
        $errorContainer.html("");
    };
    
    publicMain.toolTips = function () {
        $("[data-tooltip]").tooltip({
            "placement": "right"
        });
    };
    
    /**
     * scroll back to top of page
     */
    privateMain.backToTop = function () {
        $(document).scrollTop(0);
    };

    /**
     * On form submit of user self update form
     *
     * @param e
     */
    privateMain.userSelfUpdate = function (e) {
        var $request;
        var ajaxSettings;
        var $form = $(this);
        var $modalBody = $form.parents('.modal-body');
        var $modal = $modalBody.parents('.modal');

        var ajaxUrl = $form.attr('action');
        var formData = $form.serializeArray();
    
        // Prevent submit
        e.preventDefault();
        
        formData.push({ name: 'save', value : 1 });

        // Prepare request options
        ajaxSettings = {
            type: 'post',
            data: formData
        };

        // Perform request
        $request = $.ajax(ajaxUrl, ajaxSettings);

        $request.done(function (data, textStatus) {
            var userProfile = document.querySelector(".username.navbar-profile");
            
            userProfile.getElementsByClassName("first-name")[0].textContent = $form.find(".user-firstname").val();
            userProfile.getElementsByClassName("last-name")[0].textContent = $form.find(".user-lastname").val();
            
            $modal.modal('toggle');
        });

        $request.fail(function (jqXHR) {
            wfl.main.showErrors(jqXHR.responseText, $modal.find(".error-container"));
        });

        return false;
    };
    
    /**
     * open / close mobile action buttons in questionnaire table per click
     */
    privateMain.mobileTableStatus = function () {
        var button = this;
        $(button).closest(".td--actions").toggleClass("open");
    }
    
    /**
     * open / close mobile action buttons in questionnaire table per swipe
     */
    privateMain.handleMobileRowStatus = function () {
        $(".responsive-status").swipe({
            swipeLeft: function(e) {
                $(e.target).closest(".td--actions").removeClass("open");
            },
            swipeRight: function(e) {
                $(e.target).closest(".td--actions").addClass("open");
            },
            treshold: 0
        });
    };
    
    publicMain.init();
    
    return publicMain;
})();
